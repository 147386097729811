<template>
  <div>
    <v-banner class="lavBanner" color="#f5f8fd" single-line height="400">
      <v-img src="https://h5.ophyer.cn/official_website/banner/bannerWanqingCloud400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex max-width-1200">
          <v-card color="transparent" flat>
            <div class="d-flex flex-no-wrap">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  万擎云
                </v-card-title>
                <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px">
                  轻量易用的跨平台3D编辑工具，少量代码即可在线编辑出<br>炫酷的3D效果
                </v-card-title>
                <!-- <v-card-actions class="mt-16">
                  <v-btn
                      width="160"
                      class="font-size-20 rounded-pill"
                      color="#FFFF"
                      style="color:#2470FF"
                  >
                    立即咨询
                  </v-btn>
                </v-card-actions> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container style="max-width:1200px">
      <v-card elevation="0">
        <div class="public-title" style="margin-top:80px">
          <p>PRODUCT</p>
          <div>
            <span>产品特性</span>
          </div>
        </div>
        <v-container>
          <v-row class="lavMaxWidth justify-center">
            <v-col cols="4"  v-for="(card,index) in data.cardList" :key="index">
              <v-card elevation="0">
                <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.src" width="120" height="120">
                  <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
                  </v-img></div>
                <v-card-title class="justify-center font-size-24" v-text="card.title"></v-card-title>
                <v-card-text class="font-size-14 text-align-center" v-text="card.content" style="line-height:26px;"></v-card-text>   
              </v-card>
          </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
     <!-- class="d-flex justify-center"  -->
    <v-container style="max-width:1200px">
      <v-card class="warp warp2" :flat="true">
        <div class="public-title" style="margin-top:60px">
          <p>FUNCTION</p>
          <div>
            <span>核心功能</span>
          </div>
        </div>
        <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs">
          <v-tab v-for="(item,index) in data.items" :key="index">
            <v-card-title class="font-size-20" :style="{'color':tab == index ? '#0084ff!important':''}">{{ item.tab }}</v-card-title>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item,index) in data.items" :key="index" :transition="false">
              <div class="box">
                <transition name="slide-fade" appear>
                  <div v-show="tab === index" class="gifStyle">
                      <v-img :src='item.src' width="400" height="260" contain class="rounded-xl">
                        <v-img v-if="index == 1" src="https://h5.ophyer.cn/official_website/other/WanqingClou-5-lizi.gif" height="121" width="182" class="itemGif"></v-img>
                        <template v-slot:placeholder>
                          <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                          >
                            <v-progress-circular
                                indeterminate
                                size="80"
                                width="8"
                                color="grey lighten-3"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                  </div>
                </transition>
                <transition name="slide-fade-reverse" appear>
                  <div class="right" v-show="tab === index">
                    <v-card-title class="font-size-24 px-0 py-1"  v-text="item.title"></v-card-title>
                    <v-card-title class="font-size-16 px-0">
                      <ul  v-for="(children,index) in item.content" :key="index" :style="{'list-style': item.content.length > 1 ?'disc':'','padding':'0','white-space': 'pre-wrap'}">
                        <li style="color:#666!important;line-height:26px!important">{{children}}</li>
                      </ul>
                    </v-card-title>
                  </div>
                </transition>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <v-container class="mt-2" style="height:638px;background:#f5f8fd">
      <!-- <div class="d-flex justify-center align-center" style="width:100%;height:100%"> -->
      <div class="d-flex justify-center" style="width:100%;height:100%">
        <div style="width:100%">
          <v-card elevation="0" color="transparent">
            <div class="public-title" style="margin-top:60px">
              <p>CUSTOMER CASE</p>
              <div>
                <span>产品案例</span>
              </div>
            </div>
            <v-container style="max-width:1120px">
              <v-row class="justify-center">
                <v-col v-for="(card,index) in anli" :key="index" cols="3">
                  <v-card
                      class="mx-auto item-card "
                      max-width="240"
                      max-height="410"
                       @click="goCaseDetail(card)"
                    >
                      <v-img
                        max-height="340"
                        :src="card.coverImg"
                        :aspect-ratio="9/16"
                      >
                      <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular
                                  indeterminate
                                  size="80"
                                  color="red lighten-5"
                                ></v-progress-circular>
                              </v-row>
                      </template>
                      </v-img>
                      <v-card-text class="font-size-14 font-weight-bold sizeStyle" v-text="card.itemTitle"></v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
let _this;
import lavData from '@/static/lavData.js'
import textData from '@/static/textData.js' // 合作案例
import qs from 'qs'
export default {
data:() => ({
    imgLoad: false,
    windowHight: window.innerHeight/2,
    data:lavData.WanqingCloud,
    tab:null,
    anli:[textData.anli.mingshengyaoye,textData.anli.kislandwanju,textData.anli.paopaomate,textData.anli.wanshifujiaju]
}),

  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
}
}
</script>

<style lang="scss" scoped>
.lavBanner {
  border: none !important;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.lavMaxWidth{
  max-width: 1240px;
  margin: 0 auto;
}
.warp2 {
  .box {
    width: 1000px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 80px;
    img {
      width: 400px;
      height: 260px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    margin-left:40px;
    .v-card__text {
      padding: 0;
    }
    .v-card__title{
      line-height: 20px!important;
    }
  }
}
.item-card {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0px 1px 14px 0px rgb(0 60 255 / 8%) !important;
  border-radius: 8px;
  transition: 0.3s;
  &:hover {
    transform: scale(1.04);
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
  }
  &::after {
      content: "";
      background-image: url("./../../assets/images/home/case-icon.png");
      background-size: 100%;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 16px;
      right: 10px;
  }
}
.sizeStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gifStyle{
  position: relative;
  .itemGif{
    position: absolute;
    top: 32px;
    left: 117px;
  }
}
</style>